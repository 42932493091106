<template>
  <li
    v-if="canViewVerticalNavMenuLink(item)"
    class="nav-item"
    :class="{
      'active': isActive,
      'disabled': item.disabled,
      'has-start': item.title == 'Início', 
      'arrow-rotated': menuExpanded, // Classe para rotação da seta
    }"
  >
    <b-link
      v-bind="linkProps"
      class="d-flex align-items-center"
      :id="`link-${item.route}`"
      v-if="!item.reload"
      v-b-tooltip.hover
      :title="!menuExpanded ? t(item.title) : ''" 
    >
      <img v-if="ImageUrl" :src="ImageUrl" class="mr-1" alt="icone customizado">
      <feather-icon v-if="!ImageUrl" :icon="item.icon || 'CircleIcon'" />
      <span v-show="menuExpanded" class="menu-title text-truncate">{{ t(item.title) }}</span>
      <span @click="handleClick" class="arrow-expand-menu"></span>
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
    </b-link>
    <b-link
      v-else
      :href="item.url"
      class="d-flex align-items-center"
      :id="`link-${item.route}`"
      :disabled="this.isActive"
      v-b-tooltip.hover
      :title="!menuExpanded ? t(item.title) : ''"
    >
      <img v-if="ImageUrl" :src="ImageUrl" class="mr-1" alt="icone customizado">
      <feather-icon v-if="!ImageUrl" :icon="item.icon || 'CircleIcon'" />
      <span v-if="menuExpanded" class="menu-title text-truncate">{{ t(item.title) }}</span>
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
    </b-link>
  </li>
</template>

<script>
import { useUtils as useAclUtils } from '@core/libs/acl'
import { BLink, BBadge,VBTooltip } from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import useVerticalNavMenuLink from './useVerticalNavMenuLink'
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink'
import { computed } from '@vue/composition-api'
import store from '@/store'

export default {
  components: {
    BLink,
    BBadge,
  },
  mixins: [mixinVerticalNavMenuLink],
  directives: {
    'b-tooltip': VBTooltip
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive, toggleMenu,updateRotation } = useVerticalNavMenuLink(props.item)
    const { t } = useI18nUtils()
    const { canViewVerticalNavMenuLink } = useAclUtils()
    
    let ImageUrl = false; 

    if (props.item.icon) {
      ImageUrl = props.item.icon.indexOf(".svg") === -1 
        ? false 
        : require('@/assets/custom-icons/' + props.item.icon);
    }
   
    const handleClick = (event) => {
      event.preventDefault();
      event.stopPropagation();
      toggleMenu();
    }
    
    const menuExpanded = computed(() => store.state.verticalMenu.isMenuExpanded)
 
    return {
      isActive,
      linkProps,
      updateIsActive,
      canViewVerticalNavMenuLink,
      t,
      ImageUrl,
      toggleMenu,
      store,
      handleClick,
      updateRotation,
      menuExpanded
    }
  }
}
</script>
