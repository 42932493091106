<template>
  <div
    class="app-content"
    :class="[{'show-overlay': $store.state.app.shallShowOverlay}, $route.meta.contentClass, 
    {'content-mini': resizeHandlerReturnClassContent() === 'content-mini'}, {'content': resizeHandlerReturnClassContent() === 'content'}]"
  >
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div
      class="content-wrapper"
      :class="contentWidth === 'boxed' ? 'container p-0' : null"
    >
      <slot name="breadcrumb">
        <app-breadcrumb />
      </slot>
      <div class="content-body">
        <transition
          :name="routerTransition"
          mode="out-in"
        >
          <slot />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { computed,onUnmounted,onMounted } from '@vue/composition-api'
import useLayoutBrekpointMenu from './useLayoutBrekpointMenu'
import store from '@/store'

export default {
  components: {
    AppBreadcrumb,
  },
 
  setup() {
    const { routerTransition, contentWidth } = useAppConfig()
    const { resizeHandlerReturnClassContent } = useLayoutBrekpointMenu()
     // Ensure this function does not cause reactive updates in a loop
    const updateContentClass = () => {
      return resizeHandlerReturnClassContent()
    }

    const menuExpanded = computed(() => store.state.verticalMenu.isMenuExpanded)

    onMounted(() => {
      window.addEventListener("resize", updateContentClass);
    })

    onUnmounted(() => {
      window.removeEventListener("resize", updateContentClass);
    });

    return {
      routerTransition, contentWidth,
      store,
      menuExpanded,
      resizeHandlerReturnClassContent
    }
  },
}
</script>

