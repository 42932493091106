<template>
  <div>
    <b-nav-item-dropdown
      right
      toggle-class="d-flex align-items-center dropdown-user-link"
      class="dropdown-user"
    >
      <template #button-content>
        <div class="d-sm-flex d-none user-nav">
          <p class="user-name font-weight-bolder mb-0">
            {{ userData.fullName || userData.username }}
          </p>
        </div>
        <div class="circulo">
          <span v-text="userData.fullName.charAt(0)"></span>
        </div>
      </template>

      <b-dropdown-item
        v-if="$can('VISUALIZAR','MENU_SUPERIOR_MINHA_CONTA')"
        :to="{ name: 'meusDados'}"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="UserIcon"
          class="mr-50"
        />
        <span>Minha Conta</span>
      </b-dropdown-item>
          <b-dropdown-item
        v-else
        :to="{ name: 'notificacoes'}"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="UserIcon"
          class="mr-50"
        />
        <span>Minha Conta</span>
      </b-dropdown-item>
      <b-dropdown-item
        v-if="
          $can('VISUALIZAR','MENU_SUPERIOR_TROCAR_EMPRESA') &&
          userData.empresas.length > 1
        "
        @click="() => modalChangeCompanyStatus = true"
        link-class="d-flex align-items-center"
      >
        <feather-icon size="16" icon="RefreshCwIcon" class="mr-50" />
        <span>Trocar de Empresa</span>
      </b-dropdown-item>
      <b-dropdown-item
        :to="{ name: 'faq-ajuda-e-suporte' }"
        link-class="d-flex align-items-center"
      >
        <feather-icon size="16" icon="HelpCircleIcon" class="mr-50" />
        <span>Ajuda e Suporte</span>
      </b-dropdown-item>

      <b-dropdown-item
        :to="{ name: 'portal-politica-de-privacidade' }"
        link-class="d-flex align-items-center"
      >
        <feather-icon size="16" icon="FileTextIcon" class="mr-50" />
        <span>Política de<br />Privacidade</span>
      </b-dropdown-item>

      <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
        <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
        <span>Sair</span>
      </b-dropdown-item></b-nav-item-dropdown
    >
    <modal-change-company
      v-if="modalChangeCompanyStatus"
      @hideModal="() => modalChangeCompanyStatus = false"
    />
  </div>
</template>

<script>
import {
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import {getUserData, logoutUser} from "@/auth/utils";
import store from "@/store";
import ModalChangeCompany from "@/views/components/custom/modal-change-company/ModalChangeCompany.vue";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    ModalChangeCompany
  },
  data() {
    return {
      userData: getUserData(),
      avatarText,
      modalChangeCompanyStatus: false
    };
  },
  methods: {
    logout() {
      if (typeof window.Echo != "undefined") {
        window.Echo.leave("notificacao." + this.userData.usuario.id_usuario);
      }
      store.dispatch('verticalMenu/resetState', null, { root: true });

      logoutUser()
          .then(() => {
              this.$router.push({ name: 'auth-login' })
          })
          .catch(() => {
              this.$router.push({ name: 'auth-login' })
          })
    }
  }
};
</script>

<style lang="scss"  scoped>
.circulo {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background: #7030a0;
  overflow: hidden;
  float: center;
  position: relative;

  span {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    font-family: Montserrat, sans-serif;
    font-style: italic;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #ffffff;
  }
}
</style>
