import { $regraEntidade, $regraAcao } from '@/custom-enum/regras-enum.js'

export default [
  {
    title: 'Início',
    route: 'bem-vindo',
    icon: 'HomeIcon',
    resource: $regraEntidade.menuLateral.inicio,
    action: $regraAcao.visualizar
  }
]
