import { ref,computed} from '@vue/composition-api'
import { isNavLinkActive, navLinkProps } from '@core/layouts/utils'
import store from '@/store'

export default function useVerticalNavMenuLink(item) {
  const isActive = ref(false)
  const linkProps = navLinkProps(item)
  const menuExpanded = computed(() => store.state.verticalMenu.isMenuExpanded)

  const updateIsActive = () => {
    isActive.value = isNavLinkActive(item)
  }

  // Rotates arrow icon on hover
  const updateRotation = (val) => {
    const menuContainer = document.querySelector('.main-menu')
    if (menuContainer) {
      if (val && menuExpanded) {
        menuContainer.style.width = '260px'
      } else {
        menuContainer.style.width = '105px'
      }
    }
    store.dispatch('verticalMenu/setMenuExpanded', val)
  }

  const toggleMenu = () => {
    updateRotation(!menuExpanded.value)
  }

  return {
    isActive,
    linkProps,
    updateIsActive,
    toggleMenu,
    updateRotation,
  }
}
