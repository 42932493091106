import { $regraAcao, $regraEntidade } from "@/custom-enum/regras-enum.js";

export default [
  {
    header: "Produtos",
    resource: $regraEntidade.menuLateral.header.produtos,
    action: $regraAcao.visualizar,
  },
  {
    title: 'Academias in Company',
    icon: 'academias.svg',
    children: [
      {
        title: 'BI',
        route: 'painel-power-bi-academias-company',
        resource: $regraEntidade.menuLateral.meusProdutosAcademias.painel,
        action: $regraAcao.visualizar
      },
    ],
  },
  {
    title: "Cora",
    icon: "Cora.svg",
    children: [
      {
        title: "Aplicações",
        icon: "CircleIcon",
        route: "listagem-aplicacoes",
        resource: $regraEntidade.menuLateral.meusProdutosCora.aplicacoes.listagem,
        action: $regraAcao.visualizar,
        children: [
          {
            title: 'Ver aplicações',
            route: 'listagem-aplicacoes',
            resource: $regraEntidade.menuLateral.meusProdutosCora.aplicacoes.listagem,
            action: $regraAcao.visualizar,
          },
          {
            title: 'Criar aplicação',
            route: 'inserir-aplicacao',
            resource: $regraEntidade.menuLateral.meusProdutosCora.aplicacoes.nova,
            action: $regraAcao.visualizar
          },
          {
            title: 'Visualizar Aplicacao',
            route: 'visualizar-aplicacao',
            resource: $regraEntidade.menuLateral.meusProdutosCora.aplicacoes.visualizar,
            action: $regraAcao.visualizar,
            hidden: true,
          },
        ],
      },
      {
        title: "Questionários",
        icon: "CircleIcon",
        children: [
          {
            title: 'Ver questionários',
            route: 'listagem-questionarios',
            resource: $regraEntidade.menuLateral.meusProdutosCora.questionarios.listagem,
            action: $regraAcao.visualizar
          },
          {
            title: 'Criar questionário',
            route: 'inserir-questionario',
            resource: $regraEntidade.menuLateral.meusProdutosCora.questionarios.novo,
            action: $regraAcao.inserir
          },
          {
            title: 'Visualizar Questionario',
            route: 'editar-questionario',
            resource: $regraEntidade.menuLateral.meusProdutosCora.questionarios.visualizar,
            action: $regraAcao.visualizar,
            hidden: true,
          },
        ],
      },
    ],
  },
  {
    title: 'Cursos NR',
    route: 'cursos-nr-sesi-painel',
    icon: "cursosNR.svg",
    children: [
      {
        title: 'Painel',
        route: 'cursos-nr-sesi-painel',
        resource: $regraEntidade.menuLateral.configuracoes.painelPorEmpresaCursosNR,
        action: $regraAcao.visualizar
      },
    ]
  },
  {
    title: 'Planeja Saúde',
    route: 'planeja-saude-sesi-painel',
    icon: 'planejaSaude.svg',
    children: [
      {
        title: 'Painel',
        route: "pesquisa-painel-planeja-empresa",
        url: "/planeja-saude/painel/pesquisa",
        resource:
          $regraEntidade.menuLateral.meusProdutosPlanejaSaudeSesi.painelPorEmpresaPlanejaSaude,
        action: $regraAcao.visualizar
      },
      {
        title: "Exportação",
        route: "relatorioAcompanhamento",
        resource: $regraEntidade.menuLateral.meusProdutosPlanejaSaudeSesi.relatorioAcompanhamento,
        action: $regraAcao.visualizar,
      },
      {
        title: "Ciclos",
        route: "ciclos-planeja-saude",
        resource:  $regraEntidade.menuLateral.meusProdutosPlanejaSaudeSesi.painelPorEmpresaPlanejaSaude,
        action: $regraAcao.visualizar,
      },
      {
        title: "BI",
        route: "painel-power-bi-planeja-saude",
        resource:  $regraEntidade.menuLateral.meusProdutosPlanejaSaudeSesi.painel,
        action: $regraAcao.visualizar,
      },
    ],
  },  
  {
    title: 'Saúde Mental',
    route: 'saude-mental-sesi-painel',
    icon: 'saudeMental.svg',
    children: [
      {
        title: 'Painel',
        route: "pesquisa-painel-empresa",
        url: "/saude-mental/painel/pesquisa",
        resource: $regraEntidade.menuLateral.meusProdutoSaudeMentalProdutos.painelPorEmpresaSaudeMental,
        action: $regraAcao.visualizar
      },
      {
        title: "Ciclos",
        route: "ciclos-saude-mental",
        resource: $regraEntidade.menuLateral.meusProdutoSaudeMentalProdutos.painelPorEmpresaSaudeMental,
        action: $regraAcao.visualizar,
      },

    ],
  },
  {
    title: 'SesiFIT',
    icon: 'sesi_fit.svg',
    children: [
      {
        title: 'BI',
        route: 'painel-power-bi-sesi-fit',
        resource: $regraEntidade.menuLateral.meusProdutosSesiFit.painel,
        action: $regraAcao.visualizar
      },
    ],
  },
  {
    title: 'Outros Produtos',
    route: 'usuario-empresa-listar-outros-produtos-sesi',
    icon: 'outrosProdutos.svg',
    children: [
      {
        title: 'Ver Listagem',
        route: 'sesi-listar-outros-produtos',
        resource: $regraEntidade.menuLateral.geralOutrosProdutos,
        action: $regraAcao.visualizar
      },
      {
        title: 'Cadastrar',
        route: 'usuario-sesi-inserir-outros-produtos',
        resource: $regraEntidade.menuLateral.geralOutrosProdutos,
        action: $regraAcao.visualizar
      }
    ]
  },
];
