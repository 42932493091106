import { computed } from '@vue/composition-api'
import store from '@/store'

export default function useLayoutBrekpointMenu() {
    const menuExpanded = computed(() => store.state.verticalMenu.isMenuExpanded)
    const resizeHandlerReturnClassContent = () => {
        if (window.innerWidth >= 1200) {
          return menuExpanded.value ? 'content' : 'content-mini'
        } else if (window.innerWidth >= 992) {
          if (!menuExpanded.value) {
            store.dispatch('verticalMenu/setMenuExpanded', true)
          }
          return 'content'
        } else if (window.innerWidth >= 768 || window.innerWidth >= 576) {
          return 'content'
        } else {
          return 'content'
        }

    }
    
    return { resizeHandlerReturnClassContent }
}