import { $regraEntidade, $regraAcao } from "@/custom-enum/regras-enum.js";

export default [
  {
    header: "Configurações",
    resource: $regraEntidade.menuLateral.header.configuracoes,
    action: $regraAcao.visualizar,
  },
  {
    title: "Geral",
    icon: "GridIcon",
    children: [
      {
        title: "Usuário Sesi",
        icon: "CircleIcon",
        route: "usuarios-sesi",
        resource: $regraEntidade.menuLateral.portalUsuariosSesi,
        action: $regraAcao.visualizar,
      },
      {
        title: "Todos os ciclos",
        route: "ciclos",
        resource: $regraEntidade.menuLateral.configuracoes.ciclos,
        action: $regraAcao.visualizar,
      },
    ],
  },
  {
    title: "Ajuda e suporte",
    icon: "HelpCircleIcon",
    children: [
      {
        title: "Gerenciar tópicos",
        route: "ajuda-suporte-topicos",
        icon: "CircleIcon",
        resource: $regraEntidade.portal.ajudaESuporte.topico,
        action: $regraAcao.visualizar,
      },
      {
        title: "Gerenciar categorias",
        route: "ajuda-suporte-categorias",
        icon: "CircleIcon",
        resource: $regraEntidade.portal.ajudaESuporte.categoria,
        action: $regraAcao.visualizar,
      },
    ],
  },
];
