import { $regraEntidade, $regraAcao } from '@/custom-enum/regras-enum.js'

export default [
{
  header: 'Meus Produtos',
  resource: $regraEntidade.menuLateral.header.meusProdutos,
  action: $regraAcao.visualizar
},
{
  title: 'Academias in Company',
  icon: 'academias.svg',
  children: [
    {
      title: 'BI',
      route: 'painel-power-bi-academias-company',
      resource: $regraEntidade.menuLateral.meusProdutosAcademiasSesi.painel,
      action: $regraAcao.visualizar
    },
  ],
},
{
  title: "Cora",
  icon: "Cora.svg",
  children: [
    {
      title: "Minhas Aplicações",
      icon: "CircleIcon",
      route: "minhas-aplicacoes-cora",
      resource: $regraEntidade.menuLateral.meusProdutosCora.meus_produtos,
      action: $regraAcao.visualizar 
    },
  ],
},
{
  title: 'Cursos NR',
  route: 'cursos-nr',
  icon: "cursosNR.svg",
  children: [
    {
      title: 'Painel',
      route: 'cursos-nr-empresa-painel',
      resource: $regraEntidade.menuLateral.meusProdutosCursosNR.painel,
      action: $regraAcao.visualizar
    },
    {
      title: 'Pol. Privacidade',
      route: 'portal-politica-de-privacidade-empresa',
      resource: $regraEntidade.menuLateral.meusProdutosCursosNR.politicaDePrivacidade,
      action: $regraAcao.visualizar
    }
  ]
},

{
  title: 'Planeja Saúde',
  route: 'planeja-saude',
  icon: 'planejaSaude.svg',
  children: [
    {
      title: 'Painel',
      route: 'painel-planeja-saude',
      url:'/planeja-saude/painel',
      resource: $regraEntidade.menuLateral.meusProdutosPlanejaSaude.painel,
      action: $regraAcao.visualizar
    },
    {
      title: 'Relatório individual',
      route: 'planeja-saude-seleciona-colaborador-relatorio-individual',
      resource: $regraEntidade.menuLateral.meusProdutosPlanejaSaude.relatorioIndividual,
      action: $regraAcao.visualizar
    },
    {
      title: 'Pol. Privacidade',
      route: 'planeja-saude-politica-de-privacidade',
      resource: $regraEntidade.menuLateral.meusProdutosPlanejaSaude.politicaDePrivacidade,
      action: $regraAcao.visualizar
    },
    {
      title: "BI",
      route: "painel-power-bi-planeja-saude",
      resource:  $regraEntidade.menuLateral.meusProdutosPlanejaSaude.painelBI,
      action: $regraAcao.visualizar,
    },
  ],
},
{
  title: 'Saúde Mental',
  route: 'saude-mental',
  icon: 'saudeMental.svg',
  children: [
    {
      title: 'Painel',
      route: 'saude-mental-painel',
      url: '/saude-mental/painel',
      resource: $regraEntidade.menuLateral.meusProdutosSaudeMental.painel,
      action: $regraAcao.visualizar
    },
    {
      title: 'Resp. com Risco',
      route: 'saude-mental-respostas-compartilhadas-risco-a-vida',
      resource: $regraEntidade.menuLateral.meusProdutosSaudeMental.respostasCompartilhadasRiscoVida,
      action: $regraAcao.visualizar
    },
    {
      title: 'Resp. Compartilhadas',
      route: 'saude-mental-respostas-compartilhadas',
      resource: $regraEntidade.menuLateral.meusProdutosSaudeMental.respostasCompartilhadas,
      action: $regraAcao.visualizar
    },
    
    {
      title: 'Pol. Privacidade',
      route: 'saude-mental-politica-de-privacidade',
      resource: $regraEntidade.menuLateral.meusProdutosSaudeMental.politicaDePrivacidade,
      action: $regraAcao.visualizar
    }
  ],
}, 
{
  title: 'SesiFIT',
  icon: 'sesi_fit.svg',
  children: [
    {
      title: 'BI',
      route: 'painel-power-bi-sesi-fit',
      resource: $regraEntidade.menuLateral.meusProdutosSesiFitSesi.painel,
      action: $regraAcao.visualizar
    },
  ],
},

{
  title: 'Outros Produtos',
  route: 'usuario-empresa-listar-outros-produtos',
  icon: 'outrosProdutos.svg',
  resource: $regraEntidade.menuLateral.meusProdutosOutrosProdutos,
  action: $regraAcao.visualizar
},
]
