import { $regraEntidade, $regraAcao } from '@/custom-enum/regras-enum.js'

export default [
  {
    header: 'Empresas',
    resource: $regraEntidade.menuLateral.header.empresas,
    action: $regraAcao.visualizar
  },
  {
    title: 'Cadastrar Nova',
    route: 'empresas-matriz-criar',
    icon: 'PlusCircleIcon',
    resource: $regraEntidade.menuLateral.empresas.cadastrarNova,
    action: $regraAcao.visualizar
  },
  {
    title: 'Buscar',
    route: 'listar-empresas',
    icon: 'SearchIcon',
    resource: $regraEntidade.menuLateral.empresas.buscar,
    action: $regraAcao.visualizar
  }
]
