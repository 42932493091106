<template>
  <li
    v-if="canViewVerticalNavMenuGroup(item)"
    class="nav-item has-sub"
    :class="{
      'open': isOpen,
      'disabled': item.disabled, 
      'sidebar-group-active': isActive,
    }"
  >
    <b-link
      :class="[{'d-flex': true}, {'align-items-center':true}]"
      @click="() => updateGroupOpen(!isOpen)" 
      v-b-tooltip.hover
      :title="!menuExpanded ? t(item.title) : ''"
    >
      <img v-if="ImageUrl" :src="ImageUrl" class="mr-1" alt="icone customizado">
      <feather-icon v-if="!ImageUrl" :icon="item.icon || 'CircleIcon'" />
      <span v-if="menuExpanded" class="menu-title text-truncate">{{ t(item.title) }}</span>
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
    </b-link>
    <b-collapse
      v-model="isOpen"
      :class="{'menu-content': true, 'iconCircle':menuExpanded}"
      tag="ul"
    >
      <component
        :is="resolveNavItemComponent(child)"
        v-for="child in item.children"
        :key="child.route || child._id"
        ref="groupChild"
        :item="child"
        :class="{'d-none': child.hidden}"
      />
    </b-collapse>
  </li>
</template>

<script>
import { BLink, BBadge, BCollapse,VBTooltip } from 'bootstrap-vue'
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { useUtils as useAclUtils } from '@core/libs/acl'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import store from '@/store'
import { computed } from '@vue/composition-api'

// Composition Function
import useVerticalNavMenuGroup from './useVerticalNavMenuGroup'
import mixinVerticalNavMenuGroup from './mixinVerticalNavMenuGroup'

export default {
  name: 'VerticalNavMenuGroup',
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    BLink,
    BBadge,
    BCollapse,
  },
  mixins: [mixinVerticalNavMenuGroup],
  directives: {
    'b-tooltip': VBTooltip
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const {
      isOpen,
      isActive,
      updateGroupOpen,
      updateIsActive,
    } = useVerticalNavMenuGroup(props.item)

    const { t } = useI18nUtils()
    const { canViewVerticalNavMenuGroup } = useAclUtils()

    let ImageUrl = false; 

    if(props.item.icon) {
      ImageUrl = props.item.icon.indexOf(".svg") == -1 
        ? false 
        : require('@/assets/custom-icons/' + props.item.icon);
    }

    const menuExpanded = computed(() => store.state.verticalMenu.isMenuExpanded)

    return {
      resolveNavItemComponent,
      isOpen,
      isActive,
      updateGroupOpen,
      updateIsActive,
      menuExpanded,

      // ACL
      canViewVerticalNavMenuGroup,

      // i18n
      t,
      ImageUrl
    }
  }
}
</script>
