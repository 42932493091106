<template>
  <b-modal
    id="modal-change-company"
    ref="modal-change-company"
    size="sm"
    v-model="statusModal"
    centered
    :hide-footer="true"
    :hide-header="true"
    :no-close-on-backdrop="disabledClickOutside"
  >
    <div
      class="text-center"
    >
      <div
        class="d-flex align-items-center justify-content-center font-weight-bolder"
        style="font-size: 16px;"
      >
        <feather-icon
          size="16"
          icon="RefreshCwIcon"
          class="mr-50"
        />
        Trocar de empresa
      </div>
      <div
        class="mt-2"
      >
        Ao selecionar, os dados do ClienteSESI serão atualizados.
      </div>
      <div
        class="mt-2"
      >
        <v-select
          class="custom-select-change-company"
          @open="() => disabledClickOutside = true"
          @close="releaseDisabledClickOutside()"
          v-model="selectedCompany"
          @input="() => disabledButton = false"
          variant="custom"
          :options="companies"
          :clearable="false"
          label="nome_fantasia"
          placeholder="Selecione a empresa"
        />
      </div>
      <div
        class="mt-1"
      >
        <b-button
          variant="primary"
          class="w-100"
          @click="changeCompany"
          :disabled="disabledButton"
        >
          <span
            v-if="loading"
            class="spinner-border spinner-border-sm mr-1"
            role="status"
            aria-hidden="true"
          />
          Confirmar
        </b-button>
      </div>
    </div>
  </b-modal>
</template>
<script>
import {
  BModal,
  BButton
} from "bootstrap-vue";
import { getUserData, updateAbilities } from "@/auth/utils";
import vSelect from "vue-select";
import { modalGenericFillableErrorWithRedirectHome } from '@/libs/sweetalerts'

export default {
  components: {
    BModal,
    BButton,
    vSelect
  },

  data() {
    return {
      statusModal: true,
      companies: getUserData().empresas.filter(
        empresa => empresa.id_empresa !== getUserData().empresa.id_empresa
      ),
      selectedCompany: null,
      disabledButton: true,
      loading: false,
      disabledClickOutside: false
    };
  },

  methods: {
    async changeCompany(){
      this.loading = true
      this.disabledButton = true
      this.disabledClickOutside = true
      await this.$http.get(
        this.$api.changeCompany(
          this.selectedCompany.id_empresa
        )
      )
      .then(res => {
        this.$store.dispatch(
          'sessions/resetCompany',
          res.data
        )
        this.abilitySessionAndRedirect(res.data)
        if(this.$route.name !== 'bem-vindo'){
          this.$router.replace({ name: 'bem-vindo'})
        }
        window.location.reload()
      }).catch(() => {
        modalGenericFillableErrorWithRedirectHome(
          'Não foi possível trocar a empresa.'
        )
      })
    },
    abilitySessionAndRedirect(userData) {
      if(! userData.ability || userData.ability.length === 0){
        this.$store.dispatch('sessions/logout').then();
        this.$router.replace({ name: 'misc-not-enabled' });

        return false;
      }

      if(userData.status) {
        userData.status = 'active';
      }

      updateAbilities(userData.ability)
    },
    releaseDisabledClickOutside() {
      setTimeout(() => {
        this.disabledClickOutside = false
      }, 50)
    }
  },

  watch: {
    'statusModal': function (value) {
      if(!value){
        this.$emit('hideModal')
      }
    }
  }
};
</script>

<style lang="scss">
  .custom-select-change-company
    ::placeholder {
      color: #B9B9C3 !important;
    }
</style>
