import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { useUtils as useAclUtils } from '@core/libs/acl'
import { computed } from '@vue/composition-api'
import store from '@/store'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { t } = useI18nUtils()
    const { canViewVerticalNavMenuHeader } = useAclUtils()

    // Computed property for reactivity
    const isMenuExpanded = computed(() => store.state.verticalMenu.isMenuExpanded)

    return {
      t,
      canViewVerticalNavMenuHeader,
      isMenuExpanded,
    }
  },
  render(h) {
    const span = h('span', {}, this.t(this.item.header))
    const spanInicial = h('span', {}, '...')
    const icon = h('feather-icon', { props: { icon: 'MoreHorizontalIcon', size: '18' } })

    if (this.canViewVerticalNavMenuHeader(this.item)) {
      if (this.isMenuExpanded) {
        return h('li', { class: 'navigation-header text-truncate' }, [span, icon])
      } else {
        return h('li', { class: 'navigation-header text-truncate' }, [spanInicial, icon])
      }
    }
    return h()
  },
}
